<template>
  <div v-if="currentMeteor" style="margin:auto; margin-top:5px; position:relative; width: 50rem;">
    <MeteorComponent :meteor="currentMeteor" :meteorData="currentMeteorData" />
  </div>
  <div v-else>
    <br />
    <p>Laster inn...</p>
  </div>
</template>

<script>
import MeteorDataService from "../../services/meteor.service";
import MeteorComponent from "../../components/MeteorComponent.vue";
export default {
  name: "Meteor",
  components: {
    MeteorComponent,
  },
  data() {
    return {
      currentMeteor: null,
      currentMeteorData: null,
      message: "",
    };
  },
  methods: {
    getMeteor(id) {
      MeteorDataService.get(id)
        .then((response) => {
          this.currentMeteor = response.data;
          this.currentMeteorData = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },    
    
  },
  mounted() {
    this.message = "";
    this.getMeteor(this.$route.params.id);
  },
};
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>